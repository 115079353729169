import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@momentum-ui/core/css/momentum-ui.min.css";
import SharedLayout from "./pages/SharedLayout.jsx";
import "./App.css";
import React from "react";
import AuthProvider from "./components/AuthProvider";
import { useState } from "react";
import styled from 'styled-components';

const CenteredComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [apiToken, setApiToken] = useState();

  return (
    <BrowserRouter>
      <>
      <CenteredComponent>
      <AuthProvider apiToken={apiToken} setApiToken={setApiToken}>
        {apiToken && (
          <Routes>
            <Route path="/" element={<SharedLayout tokens={[apiToken]}/>}>
            </Route>
          </Routes>
        )}
      </AuthProvider>
      </CenteredComponent>
      </>
    </BrowserRouter>
  );
}

export default App;
