import React from "react";
import { Button } from "@momentum-ui/react";
import OAuth2 from "react-simple-oauth2-login";
import logo from "../assets/CIL-BlueLogo.svg";
import { styled } from "@stitches/react";

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const AuthProvider = ({ children, apiToken, setApiToken }) => {

  const onSuccess = (response) => {
    console.log("oauth2 success");
    setApiToken(response.code);
  };

  // must handle this failure and alert user
  const onFailure = (response) => console.error("failure", response);

  const StyledDiv = styled("div", {
    display: "flex",
    flexDirection: "column",
    padding: "2em",
    marginTop: "8em",
    height: "200px",
    width: "14em",
    textAlign: "center",
    fontSize: "2rem",
    alignItems: "center",
    justifyContent: "center",
  });

  return !apiToken ? (
    <OAuth2
      authorizationUrl="https://webexapis.com/v1/authorize"
      clientId={CLIENT_ID}
      responseType="code"
      redirectUri={`${BACKEND_HOST}/oauth/api_token`}
      isCrossOrigin={false}
      scope="spark%3Akms%20spark%3Apeople_read%20spark%3Arooms_read%20spark%3Amessages_write%20spark%3Arooms_write"
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={(props) => (
        <>
          <StyledDiv>
            <img alt="Cisco Innovation Labs logo " src={logo} width="190" height="119" />
            <hr style={{ width: "9em", color: "#3D3D3D" }} />
            <div style={{ fontFamily: "CiscoSansTTExtraLight" }}>
              <span>Cisco Electricitymaps API Gateway</span>
            </div>
            <hr style={{ width: "9em", color: "#3D3D3D" }} />
            <Button containerLarge size={36} color="#3D3D3D" expand {...props}>
              Generate API Key
            </Button>
          </StyledDiv>
        </>
      )}
    />
    ) : (
      children
    );
};

export default AuthProvider;
