import React from "react";
import { Outlet } from "react-router-dom";
import TokenList from "../components/TokenList";
import "../App.css";

const SharedLayout = (tokens) => {
  return (
    <div className="App">
      <Outlet />
      <TokenList tokens={tokens}/>
    </div>
  );
};

export default SharedLayout;
